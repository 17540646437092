<template>
  <div class="g-box g-role-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
              :model="deviceInfoForm" class="demo-form-inline" size="small"
              :rules="{}" ref="deviceInfoForm" label-width="150px"
      >
        <h2 class="m-tt">添加排放源</h2>
        <div style="margin-left: -40px;">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="排放源名称" prop="name">
                <span>{{deviceInfoForm.name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排放源类型" prop="typeId">
                <span>{{ emissionsEnums.find(ele=>ele.value==deviceInfoForm.typeId)?emissionsEnums.find(ele=>ele.value==deviceInfoForm.typeId).label:'' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="部门" prop="orgId">
                {{deviceInfoForm.orgName}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="型号" prop="model">
                {{deviceInfoForm.model}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所在地" prop="storageAddress">
                {{deviceInfoForm.storageAddress}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="负责人" prop="principalId">
                <span>{{ userEnums.find(ele=>ele.value==deviceInfoForm.principalId)?userEnums.find(ele=>ele.value==deviceInfoForm.principalId).label:'' }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="状态" prop="status">
                <span>{{ deviceInfoForm.status?'启用':'停用' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="内容" prop="remark">
                <span>{{ deviceInfoForm.remark }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <h2 class="m-tt">碳排放计算计算方法
<!--          <span>-->
<!--            <el-radio-group readOnly v-model="deviceInfoForm.countType" @change="changeCountType">-->
<!--                  <el-radio :label="1">碳质量平衡法</el-radio>-->
<!--                  <el-radio :label="2">因子法</el-radio>-->
<!--            </el-radio-group>-->
<!--          </span>-->
        </h2>
        <div style="margin-left: 0px;">
          <div class="g-form-add-box">
            <div>
              <el-radio-group v-model="factorType" size="small" v-if="deviceInfoForm.countType==1">
                <el-radio-button label="1" plain>原材料<span class="required">*</span></el-radio-button>
                <el-radio-button label="2" plain>产出物<span class="required">*</span></el-radio-button>
                <el-radio-button label="3" plain>含碳废弃物</el-radio-button>
              </el-radio-group>
            </div>
            <el-form-item label-width="30px" prop="emissionsFactorList">
              <div>
                <el-table :data="emissionsFactorList.filter(ele=>ele.emissionsFactorType==factorType)">
                  <el-table-column prop="consumeMaterial" label="材料名称" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.consumeMaterial }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="consumeMaterialUnit" label="材料单位" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.consumeMaterialUnit }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="factorLevel" label="因子" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.factor }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="因子单位" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.factorUnit }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dataSource" label="数据来源" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.dataSource }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="activityLevelId" width="120" label="活动水平不确定性评分" align="center">
                    <template slot-scope="scope">
                      <span>{{ factorActivityLevelTypeEnums.find(ele=>ele.value==scope.row.activityLevelId)?factorActivityLevelTypeEnums.find(ele=>ele.value==scope.row.activityLevelId).label:'' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="frequencyId" width="120" label="校正频率评分" align="center">
                    <template slot-scope="scope">
                      <span>{{ factorFrequencyTypeEnums.find(ele=>ele.value==scope.row.frequencyId)?factorFrequencyTypeEnums.find(ele=>ele.value==scope.row.frequencyId).label:'' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="qualityLevelId" label="因子等级" align="center">
                    <template slot-scope="scope">
                      <span>{{ factorQualityLevelEnums.find(ele=>ele.value==scope.row.qualityLevelId)?factorQualityLevelEnums.find(ele=>ele.value==scope.row.qualityLevelId).label:'' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="factorDescription" label="备注" align="center">
                    <template slot-scope="scope">
                      <div style="text-align: center;">{{ scope.row.factorDescription }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                          fixed="right"
                          label="操作"
                          width="100"
                  >
                    <template slot-scope="scope">
                      <el-button
                              type="text"
                              size="small"
                      >
                        <span @click="handleAddSelfTest(scope.row, scope.$index)">自测值</span>
                      </el-button>
                      <el-button
                              @click="handleRemoveRow(scope.row, scope.$index)"
                              type="text"
                              size="small"
                      >
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
              class="u-btn s-cancel" size="small"
              @click="$router.go(-1)"
      >
        返回
      </el-button>
    </div>
    <my-dialog ref="myDialog"></my-dialog>
    <my-dialog2 ref="myDialog2" readonly></my-dialog2>

  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import base from './emission-add'
import tool from '@/tool'

export default Vue.extend({
  components: {
  },
  directives: {},
  filters: {},
  extends: base,
  props: {},
  data () {
    return {

    }
  },
  computed: {
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {

  }
})
</script>
